<template>
  <div>
    <CRow>
      <CCol md="12">
        <CCard>
          <CCardHeader>
            <strong>QMS Template</strong>
          </CCardHeader>
          <CRow>
            <CCol md="8">
              <CCardBody>
                <CForm>
                  <CInput
                    label="Template Name *"
                    v-model="templates.templateName"
                    horizontal
                    :disabled="mode.viewMode"
                    :invalid-feedback="'Please insert the Template Name'"
                    @blur="$v.templates.templateName.$touch()"
                    :is-valid="
                      $v.templates.templateName.$dirty
                        ? !$v.templates.templateName.$error
                        : null
                    "
                  >
                  </CInput>
                </CForm>
              </CCardBody>
            </CCol>
          </CRow>

          <CCardFooter align="right">
            <CButton
              v-if="mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="primary"
              @click="editMode()"
            >
              <CIcon name="cil-pencil" /> Edit
            </CButton>

            <CButton
              v-if="!mode.viewMode"
              class="mr-2"
              type="submit"
              size="sm"
              color="success"
              @click="save()"
            >
              <CIcon name="cil-check-circle" /> Save
            </CButton>

            <CButton
              class="mr-2"
              type="Cancel"
              size="sm"
              color="danger"
              @click="cancel()"
            >
              <CIcon name="cil-ban" /> Cancel
            </CButton>
          </CCardFooter>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import Vue from "vue";
import datetime from "vuejs-datetimepicker";
import {
  required,
  minLength,
  maxLength,
  url,
  requiredIf,
} from "vuelidate/lib/validators";
export default {
  name: "QMSTemplate",
  data() {
    return {
      mode: {
        newMode: false,
        editMode: false,
        viewMode: false,
      },

      qmsTemplateId: "",
      templates: {
        qmsTemplateId: "0",
        templateName: "",
      },

      horizontal: { label: "col-3", input: "col-9" },
    };
  },
  validations: {
    templates: {
      templateName: {
        required,
      },
    },
  },
  created() {
    if (this.$route.params.mode == "new") {
      this.mode.newMode = true;
      this.mode.viewMode = false;
      this.mode.editMode = false;
    }
    if (this.$route.params.mode == "view") {
      this.mode.viewMode = true;
      this.qmsTemplateId = this.$route.params.qmsTemplateId;
      this.getTemplate();
    }
    if (this.$route.params.mode == "edit") {
      this.mode.editMode = true;
      this.qmsTemplateId = this.$route.params.qmsTemplateId;
      this.getTemplate();
    }
  },

  methods: {
    editMode() {
      this.mode.editMode = true;
      this.mode.viewMode = false;
    },
    cancel() {
      this.$router.push({ name: "QMSTemplates" });
    },

    save() {
      this.$v.templates.$touch();
      if (this.$v.templates.$invalid) {
        console.log("form is invalid", JSON.stringify(this.$v.templates));
      } else {
        // fetch(process.env.VUE_APP_API_HOST + "/emapp/web/secure/save/qms/template", {
        //   method: "POST",
        //   headers: {
        //     "Content-type": "application/json",
        //   },
        //   body: JSON.stringify(this.templates),
        // })

        this.apiPostSecure(
          process.env.VUE_APP_API_HOST + "/emapp/web/secure/save/qms/template",
          this.template
        ).then((templates) => {
          console.log(JSON.stringify(templates));
          this.$router.push({ name: "QMSTemplates" });
        });
      }
    },

    validator(val) {
      return val ? val.length >= 4 : false;
    },

    getTemplate() {
      this.apiGetSecure(
        process.env.VUE_APP_API_HOST +
          "/emapp/web/secure/get/qms/template/" +
          this.qmsTemplateId
      )
        .then((response) => response.json())
        .then((data) => (this.templates = data));
    },
  },
  mounted() {},
};
</script>
